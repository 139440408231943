import { Axios } from "../config";
import { getAuthToken } from "../utils/auth_token.utils";

const formSubmit = async (formValues, authTokenFromState, url, formEdit) => {
    console.log("about to show URL");
    console.log(formEdit)
    console.log(url);

    try {
        const tokenHeader = getAuthToken(authTokenFromState);

        let response;
        if (formEdit) {
            response = await Axios.patch(url, formValues, {
                headers: tokenHeader
            });
        } else {
            response = await Axios.post(url, formValues, {
                headers: tokenHeader
            });
        }

        return {
            status: response.status,
            data: response.data
        };
    } catch (error) {
        if (error.response) {
            if (error.response.status === 400 ||
                error.response.status === 401 ||
                error.response.status === 404
            ) {
                if (error.response.data !== null) {
                    throw {
                        name: "client_error",
                        message: error.response.data
                    }
                }
            } else {
                throw {
                    name: "server_error",
                    message: "System error, please try again after few moments. If you still can’t save, contact your administrator."
                }
            }
        }
        else if (error.request) {
            throw {
                name: "network_error",
                message: "Please, make sure you are connected to the network. If you still can’t save, contact your administrator."
            }
        }
        else {
            throw {
                name: "unknown_error",
                message: "An unknown error occurred, please contact your administrator."
            }
        }
    }
}

export default formSubmit;