import { format } from 'date-fns';
import FormTableCalculateDisplay from '../../../components/form-table-calculate/form-table-calculate.component';
import FormTableHeaderCell from '../../../components/form-table-header-cell/form-table-header-cell.component';
import FormTableInlineDelete from '../../../components/form-table-inline-delete/form-table-inline-delete.component';
import FormTableSelect from '../../../components/form-table-select/form-table-select.component';
import FormTableTextField from '../../../components/form-table-text-field/form-table-text-field.component';

export const COMPANY_INFO = {
  formTitle: "Company",
  formTitleForNew: "Company",
  path: "/company",
  listPage: "/company"
}

export const COMPANY_FORM_TEMPLATE = {
  noAbalityToAddNew: true,
  bodySections: {
    basic: {
      sectionType: "basic",
      sectionTypeId: "BASIC",
      expandable: false,
      breakPoint: 1,
      fieldName: "company",
      fieldItems: [
        {
          label: "Company Name",
          type: "text",
          name: "companyName",
          initialFocus: false,
          validationProps: {
            required: "Company Name is required"
          }
        },
        {
          label: "Company TIN",
          type: "text",
          name: "companyTin",
          initialFocus: false,
          validationProps: {
            required: "Company TIN is required"
          }
        }
      ]
    },
    addressDetails: {
      sectionType: "address details",
      sectionTypeId: "addressDetails",
      multiForm: false,
      expandable: true,
      addButton: true,
      breakPoint: 5,
      position: "LAST",
      fieldName: "companyAddress",
      fieldItems: [
        {
          label: "Street",
          type: "text",
          name: "street",
          required: false,
          initialFocus: false
        },
        {
          label: "City",
          type: "text",
          name: "city",
          required: false,
          initialFocus: false
        },
        {
          label: "State",
          type: "text",
          name: "state",
          size: "small",
          required: false,
          initialFocus: false
        },
        {
          label: "Zip code",
          type: "text",
          name: "zipCode",
          size: "small",
          required: false,
          initialFocus: false
        },
        {
          label: "Country",
          type: "text",
          name: "country",
          required: false,
          initialFocus: false
        },
        {
          label: "Phone no 1",
          type: "text",
          size: "small",
          name: "phoneNumber1",
          required: false,
          initialFocus: false
        },
        {
          label: "Phone no 2",
          type: "text",
          size: "small",
          name: "phoneNumber2",
          required: false,
          initialFocus: false
        },
        {
          label: "Email",
          type: "text",
          name: "email",
          required: false,
          initialFocus: false,
          validationProps: {
            pattern: {
              value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              message: 'Valid email required on payment address details',
            }
          }
        },
        {
          label: "Website",
          type: "text",
          name: "website",
          required: false,
          initialFocus: false
        }
      ]
    },
  },
  watchFields: []
}
