import { format } from 'date-fns';
import TableCellStatus from '../../components/table-cell-status/table-cell-status.component';
import { displayCellValue, displayCellValueNumberToTwoDecimal } from '../../utils/column-values.util';
import { useMediaQuery } from 'react-responsive';
import { formatNumberToTwoDecimal } from '../../utils/format-number.util';

export const ITEM_HISTORY_INFO = {
  formTitle: "Product",
  path: "/items",
  subPath: "/itemhistory",
  listPage: "/items",
  pagePath: {
    salesOrder: "sales-orders",
    purchaseOrder: "purchase-orders",
    stockTransfer: "stock-transfers",
    stockAdjustment: "stock-adjustments",
    workOrder: "work-orders",
    beginningStock: "items"
  },
  report: true,
  hasDateRange: false,
  hasLocationFilter: true,
  isNoExportAndPrint: true
}

export const ITEM_HISTORY_COLUMN_TEMPLATE = [
  {
    Header: "Activity",
    accessor: "type"
  },
  {
    Header: "Code",
    accessor: "code",
    Cell: ({ value }) => {
      return displayCellValue(value);
    }
  },
  {
    Header: "Date",
    accessor: "date",
    Cell: ({ value }) => {

      return format(new Date(value), 'dd/MM/yyyy');
    }
  },
  {
    Header: "Branch",
    accessor: "location",
    Cell: ({ value }) => {
      return displayCellValue(value);
    }
  },
  {
    Header: "Initial",
    accessor: "initial",
    Cell: ({ value }) => {
      return displayCellValue(formatNumberToTwoDecimal(value));
    }
  },
  {
    Header: "Quantity Changes",
    accessor: "qty",
    Cell: ({ value }) => {
      const isMobileView = useMediaQuery({ maxWidth: 991 });
      const isRightAligned = true;

      if (!value) {
        return displayCellValue(value);
      }

      return (
        <div className={isMobileView && isRightAligned ?
          'report-table__body__row__cell--right-aligned-mobile-cell' : ''}>
          <TableCellStatus
            value={value}
            noDot={true}
          />
        </div>
      )
    }
  },
  {
    Header: "Final",
    accessor: "final",
    Cell: ({ value }) => {
      return displayCellValue(formatNumberToTwoDecimal(value));
    }
  },
]
