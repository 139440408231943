import Modal from './modal.componenet';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectModalFormTemplate, selectModalType, selectShowSupplierModal } from '../../redux/modal/modal.reselect';

import withModalFormTemplate from '../with-modal-form-template/with-modal-form-template.component';

const mapStateToProps = createStructuredSelector({
    modalType: selectModalType,
    formTemplate: selectModalFormTemplate,
});

const ModalContainer = connect(mapStateToProps)(withModalFormTemplate(Modal));

export default ModalContainer;