import { displayCellValue } from '../../../utils/column-values.util';
import { useMediaQuery } from 'react-responsive';

export const CUSTOMERS_COLUMNS = [
    {
        Header: "Customer Name",
        accessor: "customerName",
        style: {
            position: "sticky",
            left: 0,
            top: 0,
            zIndex: 2
        }
    },
    {
        Header: "Customer Id",
        accessor: "customerCode",
        Cell: ({ value }) => {
            const isMobileView = useMediaQuery({ maxWidth: 991 });

            if (isMobileView) {
                return null;
            }

            return displayCellValue(value);
        }
    },
    {
        Header: "Customer Tin",
        accessor: "customerTin",
        Cell: ({ value }) => {
            const isMobileView = useMediaQuery({ maxWidth: 991 });

            if (isMobileView) {
                return `Tin: ${displayCellValue(value)}`;
            }


            return displayCellValue(value);
        }
    },
    {
        Header: "Customer Phone Number",
        accessor: "customerPhone",
        Cell: ({ value }) => {
            const isMobileView = useMediaQuery({ maxWidth: 991 });

            if (isMobileView) {
                return `Phone: ${displayCellValue(value)}`;
            }

            return displayCellValue(value);
        }
    }
]