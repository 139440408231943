import { useEffect, useState } from "react";
import "./stock-details.component.scss";
import { formatNumberToTwoDecimal } from "../../utils/format-number.util";

const StockDetails = ({
    editSectionData
}) => {
    const [qtyOnHand, setQtyOnHand] = useState(0);

    useEffect(() => {
        setQtyOnHand(editSectionData.reduce((preVale, stock) => (
            stock.qtyOnHand + preVale
        ), 0))
    }, [])
    return (
        <div className="stock-detials">
            <div className={`stock-detials__total ${!qtyOnHand && "stock-detials__total--noBottomBorderPadding"}`}>
                <div className="stock-detials__total__single">
                    {editSectionData.length ?
                        <div className="stock-detials__total__single__title">
                            Total Quantity on Hand
                        </div> : null
                    }
                    {editSectionData.length ?
                        <div className="stock-detials__total__single__content">
                            {formatNumberToTwoDecimal(qtyOnHand)}
                        </div> :
                        <p className="stock-detials__total__single__content__non-stock">
                            Non stocked Products do not track stock.
                        </p>
                    }
                </div>
            </div>
            {
                editSectionData.map(stock => (
                    <div className="stock-detials__branch">
                        <div className="stock-detials__branch__title">
                            {stock.location}
                            {stock.locationName &&
                                <span className="stock-detials__branch__title__subtitle">
                                    | {stock.locationName}
                                </span>
                            }
                        </div>
                        <div className="stock-detials__branch__breakdown">
                            <div className="stock-detials__branch__breakdown__single">
                                <div className="stock-detials__branch__breakdown__single__title">
                                    Quanitiy On Hand
                                </div>
                                <div className="stock-detials__branch__breakdown__single__content">
                                    {formatNumberToTwoDecimal(stock.qtyOnHand)}
                                </div>
                            </div>
                            <div className="stock-detials__branch__breakdown__single">
                                <div className="stock-detials__branch__breakdown__single__title">
                                    Reserved
                                </div>
                                <div className="stock-detials__branch__breakdown__single__content">
                                    {formatNumberToTwoDecimal(stock.reserve)}
                                </div>
                            </div>
                            <div className="stock-detials__branch__breakdown__single">
                                <div className="stock-detials__branch__breakdown__single__title">
                                    Available
                                </div>
                                <div className="stock-detials__branch__breakdown__single__content">
                                    {formatNumberToTwoDecimal(stock.available)}
                                </div>
                            </div>
                        </div>
                    </div>
                ))
            }


        </div>
    )
}

export default StockDetails;