import { format } from 'date-fns';
import FormTableCalculateDisplay from '../../../components/form-table-calculate/form-table-calculate.component';
import FormTableHeaderCell from '../../../components/form-table-header-cell/form-table-header-cell.component';
import FormTableInlineDelete from '../../../components/form-table-inline-delete/form-table-inline-delete.component';
import FormTableSelect from '../../../components/form-table-select/form-table-select.component';
import FormTableTextField from '../../../components/form-table-text-field/form-table-text-field.component';

export const EMPLOYEE_INFO = {
  formTitle: "Employee",
  formTitleForNew: "New Employee",
  path: "/employees",
  listPage: "/employees",
}

export const EMPLOYEE_FORM_TEMPLATE = {
  bodySections: {
    basic: {
      sectionType: "basic",
      sectionTypeId: "BASIC",
      expandable: false,
      breakPoint: 1,
      fieldName: "employee",
      fieldItems: [
        {
          label: "Employee Code",
          type: "text",
          name: "employeeCode",
          initialFocus: false,
          validationProps: {
            required: "Employee Code is required"
          }
        },
        {
          label: "Employee Name",
          type: "text",
          name: "firstName",
          initialFocus: false,
          validationProps: {
            required: "Employee Name is required"
          }
        }
      ]
    }
  },
  watchFields: []
}
