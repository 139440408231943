import "./drop-down-button.styles.scss";

const DropDownButton = ({
    handleClickDropDownBtn,
    content,
    icon,
    iconLeft,
    reference,
    inverted
}) => {
    const Icon = icon
    const IconLeft = iconLeft

    return (
        <button
            className={`drop-down-button ${inverted ? "drop-down-button--inverted" : null}`}
            onClick={handleClickDropDownBtn} ref={reference}>
            {iconLeft ?
                <IconLeft className={`drop-down-button__icon drop-down-button__icon--right-margin
            ${inverted ? "drop-down-button__icon--inverted" :
                        "drop-down-button__icon"}`} /> :
                null
            }
            {content && <span className={`drop-down-button__content`}>{content}</span>}
            {icon ?
                <Icon className={`drop-down-button__icon ${inverted ? "drop-down-button__icon--inverted" :
                    "drop-down-button__icon"}`} /> :
                null
            }
        </button>
    )
};

export default DropDownButton;