import { format } from 'date-fns';
import FormTableCalculateDisplay from '../../../components/form-table-calculate/form-table-calculate.component';
import FormTableHeaderCell from '../../../components/form-table-header-cell/form-table-header-cell.component';
import FormTableInlineDelete from '../../../components/form-table-inline-delete/form-table-inline-delete.component';
import FormTableSelect from '../../../components/form-table-select/form-table-select.component';
import FormTableTextField from '../../../components/form-table-text-field/form-table-text-field.component';

export const SUPPLIER_INFO = {
  formTitle: "Supplier",
  formTitleForNew: "New Supplier",
  path: "/suppliers",
  listPage: "/suppliers",
}

export const SUPPLIER_FORM_TEMPLATE = {
  bodySections: {
    basic: {
      sectionType: "basic",
      sectionTypeId: "BASIC",
      expandable: false,
      breakPoint: 2,
      fieldName: "supplier",
      fieldItems: [
        {
          label: "Supplier Code",
          type: "text",
          name: "supplierCode",
          initialFocus: false,
          validationProps: {
            required: "Supplier Code is required"
          }
        },
        {
          label: "Supplier name",
          type: "text",
          name: "supplierName",
          required: true,
          initialFocus: false,
          validationProps: {
            required: "Supplier Name is required"
          }
        },
        {
          label: "Supplier TIN number",
          type: "text",
          name: "supplierTinNumber",
          required: true,
          initialFocus: false,
          validationProps: {
            required: "Supplier TIN Number is required"
          }
        }
      ]
    },
    contactDetails: {
      sectionType: "contact",
      sectionTypeId: "contactDetails",
      multiForm: true,
      mutliFormTitle: "Contact",
      expandable: true,
      addButton: true,
      breakPoint: 3,
      fieldName: "supplierContact",
      fieldItems: [
        {
          label: "Contact Name",
          type: "text",
          name: "contactName",
          required: false,
          initialFocus: false
        },
        {
          label: "Phone Number 1",
          type: "text",
          name: "phoneNumber1",
          required: false,
          initialFocus: false,
          break: true
        },
        {
          label: "Phone Number 2",
          type: "text",
          name: "phoneNumber2",
          required: false,
          initialFocus: false
        },
        {
          label: "Email",
          type: "text",
          name: "email",
          required: false,
          initialFocus: false,
          validationProps: {
            pattern: {
              value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              message: 'Valid email required on contact details',
            }
          }
        }
      ]
    },
    addressDetails: {
      sectionType: "address",
      sectionTypeId: "addressDetails",
      multiForm: true,
      mutliFormTitle: "address",
      expandable: true,
      addButton: true,
      breakPoint: 5,
      fieldName: "supplierAddress",
      fieldItems: [
        {
          label: "Street",
          type: "text",
          name: "street",
          required: false,
          initialFocus: false
        },
        {
          label: "City",
          type: "text",
          name: "city",
          required: false,
          initialFocus: false
        },
        {
          label: "State",
          type: "text",
          name: "state",
          size: "small",
          required: false,
          initialFocus: false
        },
        {
          label: "Zip Code",
          type: "text",
          name: "zipCode",
          size: "small",
          required: false,
          initialFocus: false
        },
        {
          label: "Country",
          type: "text",
          name: "country",
          required: false,
          initialFocus: false
        },
        {
          label: "Phone Number 1",
          type: "text",
          size: "small",
          name: "phoneNumber1",
          required: false,
          initialFocus: false
        },
        {
          label: "Phone Number 2",
          type: "text",
          size: "small",
          name: "phoneNumber2",
          required: false,
          initialFocus: false
        },
        {
          label: "Email",
          type: "text",
          name: "email",
          required: false,
          initialFocus: false,
          validationProps: {
            pattern: {
              value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              message: 'Valid email required on payment address details',
            }
          }
        },
        {
          label: "Website",
          type: "text",
          name: "website",
          required: false,
          initialFocus: false
        },
        // {
        //   label: "Address Type",
        //   type: "select",
        //   name: "SupplierAddressTypes",
        //   required: false,
        //   initialFocus: false,
        //   url: "/supplieraddresstypes"
        // }
      ]
    },
    bankAccountDetails: {
      sectionType: "bank account",
      sectionTypeId: "bankAccountDetails",
      multiForm: true,
      mutliFormTitle: "Bank Account",
      expandable: true,
      addButton: true,
      position: "LAST",
      breakPoint: 2,
      fieldName: "supplierBankAccount",
      fieldItems: [
        {
          label: "Bank Name",
          type: "text",
          name: "bankName",
          required: false,
          initialFocus: false
        },
        {
          label: "Account Name",
          type: "text",
          name: "accountName",
          required: false,
          initialFocus: false
        },
        {
          label: "Account Number",
          type: "text",
          name: "accountNumber",
          required: false,
          initialFocus: false
        }
      ]
    }
  },
  watchFields: []
}
