export const modalActionTypes = {
    SHOW_ADD_SUPPLIER_MODAL: "SHOW_ADD_SUPPLIER_MODAL",
    SHOW_EDIT_SUPPLIER_MODAL: "SHOW_EDIT_SUPPLIER_MODAL",
    SHOW_ADD_SUPPLIER_GROUP_MODAL: "SHOW_ADD_SUPPLIER_GROUP_MODAL",
    SHOW_EDIT_SUPPLIER_GROUP_MODAL: "SHOW_EDIT_SUPPLIER_GROUP_MODAL",
    SHOW_ADD_ITEM_GROUP_MODAL: "SHOW_ADD_ITEM_GROUP_MODAL",
    SHOW_EDIT_ITEM_GROUP_MODAL: "SHOW_EDIT_ITEM_GROUP_MODAL",
    SHOW_ADD_ITEM_TAX_MODAL: "SHOW_ADD_ITEM_TAX_MODAL",
    SHOW_EDIT_ITEM_TAX_MODAL: "SHOW_EDIT_ITEM_TAX_MODAL",
    SHOW_ADD_UNIT_OF_MEASURE_MODAL: "SHOW_ADD_UNIT_OF_MEASURE_MODAL",
    SHOW_EDIT_UNIT_OF_MEASURE_MODAL: "SHOW_EDIT_UNIT_OF_MEASURE_MODAL",
    SHOW_ADD_ITEM_MODAL: "SHOW_ADD_ITEM_MODAL",
    SHOW_EDIT_ITEM_MODAL: "SHOW_EDIT_ITEM_MODAL",
    SHOW_ADD_CUSTOMER_GROUP_MODAL: "SHOW_ADD_CUSTOMER_GROUP_MODAL",
    SHOW_EDIT_CUSTOMER_GROUP_MODAL: "SHOW_EDIT_CUSTOMER_GROUP_MODAL",
    SHOW_ADD_LOCATION_MODAL: "SHOW_ADD_LOCATION_MODAL",
    SHOW_EDIT_LOCATION_MODAL: "SHOW_EDIT_LOCATION_MODAL",
    SHOW_ADD_CUSTOMER_MODAL: "SHOW_ADD_CUSTOMER_MODAL",
    SHOW_EDIT_CUSTOMER_MODAL: "SHOW_EDIT_CUSTOMER_MODAL",
    SHOW_STOCK_ENTRY_MODAL: "SHOW_STOCK_ENTRY_MODAL",
    SHOW_ADD_PURCHASE_ORDER_MODAL: "SHOW_ADD_PURCHASE_ORDER_MODAL",
    SET_EDIT_UUID: "SET_EDIT_UUID",
    SET_EDIT_DATA: "SET_EDIT_DATA",
    SET_MODAL_EXPAND_SECTION: "SET_MODAL_EXPAND_SECTION",
    SET_MODAL_FORM_TEMPLATE: "SET_MODAL_FORM_TEMPLATE",
    SET_MODAL_TYPE: "SET_MODAL_TYPE",
    ADD_MORE_PART: "ADD_MORE_PART",
    REMOVE_PART: "REMOVE_PART",
    SET_MODAL_FIELD_VALUE: "SET_MODAL_FIELD_VALUE",
    SUBMIT_MODAL_FORM_START: "SUBMIT_MODAL_FORM_START",
    SUBMIT_MODAL_FORM_SUCCESS: "SUBMIT_MODAL_FORM_SUCCESS",
    SUBMIT_MODAL_FORM_FAILURE: "SUBMIT_MODAL_FORM_FAILURE",
    SET_MODAL_FORM_VALIDATION_ERROR: "SET_MODAL_FORM_VALIDATION_ERROR",
    SET_IS_FAIL: "SET_IS_FAIL",
    SET_WINDOW_HEIGHT: "SET_WINDOW_HEIGHT",
    SET_IS_FETCHING: "SET_IS_FETCHING"
}