export const formatNumberToTwoDecimal = (number) => {
    const numberToBeFormated = isNaN(number) && number ? number.split(',').join('') : number
    const roundedNumber = (Math.round(numberToBeFormated * 100) / 100).toFixed(2);
    return new Intl.NumberFormat('en-US').format(roundedNumber)
}

export const stringToNumber = (stringNumber) => {
    if (stringNumber && isNaN(stringNumber) && stringNumber != "") {
        const stringNumberRemovedComma = stringNumber.split(',').join('');
        return parseFloat(stringNumberRemovedComma);
    }

    const newNumber = parseFloat(stringNumber);
    return newNumber ? newNumber : 0
}

export const roundNumberTwoDecimal = (number) => {
    if (isNaN(number)) {
        return 0;
    }

    return ((Math.round(number * 100) / 100).toFixed(2))
}