import React from "react";
import FormFieldError from "../form-field-error/form-field-error.componenet";
import { useFormContext, Controller } from "react-hook-form";
import debounce from 'debounce'

import "./form-table-text-field.styles.scss";
import { useEffect } from "react";
import { useRef } from "react";
import { formatNumberToTwoDecimal, formatNumberWithComma } from "../../utils/format-number.util";
import { connect } from "react-redux";
import { setValueEntredBlur } from "../../redux/form/form.actions";

const FormTableTextField = ({
    label,
    type,
    handleChange,
    initialFocus,
    onChange,
    required,
    fieldName,
    rowNumber,
    valueType,
    fieldItemName,
    validationProps,
    fieldIndex,
    sectionTypeId,
    defaultValue,
    align,
    handleFocusOut,
    setValueEntredBlur,
    placeHolder,
    headerName,
    isMobileView,
    ...otherProps }) => {
    const { register, formState: { errors }, control } = useFormContext();
    const name = fieldName + '.' + rowNumber + '.' + fieldItemName;

    const valueEntered = () => {
        setValueEntredBlur(true)
    }

    return (
        <div className="form-table-text-field">
            <div className={`${isMobileView ? "form-table-text-field--mobile" : ""}`}>
                {
                    isMobileView &&
                    <label className="form-table-text-field__label">
                        {headerName}
                    </label>
                }
            </div>
            <Controller
                control={control}
                {...register(name, validationProps)}
                ref={null}
                defaultValue={""}
                render={({
                    field: { onChange, onBlur, value, name, ref },
                }) => {
                    return (
                        <input
                            className={`form-table-text-field__input 
                                ${errors[fieldName]?.[rowNumber]?.[fieldItemName] && "form-table-text-field__input--error"}
                                ${align === "right" ? "form-table-text-field__input--right" : null}
                                ${isMobileView ? "form-table-text-field__input--mobile" : null}
                            `}
                            type={type}
                            value={value}
                            onChange={onChange}
                            autoFocus={initialFocus}
                            {...otherProps}
                            placeholder={placeHolder ? placeHolder : valueType === "number" ? "0.00" : ""}
                            onBlur={(event) => {
                                valueEntered();
                                const { value } = event.target;
                                if (value !== "" && valueType === "number") {
                                    onChange(formatNumberToTwoDecimal(value))
                                } else {
                                    onChange(value)
                                }
                            }}
                        />

                    )
                }}
            />

            {errors?.[fieldName]?.[rowNumber]?.[fieldItemName] && <FormFieldError errorMessage={errors?.[fieldName]?.[rowNumber]?.[fieldItemName].message} />}
        </div>
    )
}

const mapDispatchToProps = dispatch => ({
    setValueEntredBlur: (data) =>
        dispatch(setValueEntredBlur(data)),
})

export default connect(null, mapDispatchToProps)(FormTableTextField);