
import { useState, useEffect } from "react";
import { Axios } from "../config";
import { getAuthToken } from '../utils/auth_token.utils'

const useFetchActiveState = (
    endpoint,
    authTokenFromState,
    setisDeactivate,
    isDeactivate
) => {
    const [activeStateData, setActiveStateData] = useState([]);
    const [activeStateError, setActiveStateError] = useState(null);
    const [isActiveStateLoading, setIsActiveStateLoading] = useState(false);

    const tokenHeader = getAuthToken(authTokenFromState);
    const fetchData = async () => {
        try {
            setIsActiveStateLoading(true);
            const response = await Axios.get(endpoint, {
                headers: tokenHeader
            })

            setActiveStateData(response.data);
            setIsActiveStateLoading(false);
            setisDeactivate(false);
        } catch (error) {
            console.log(error)
            if (error.response) {
                if (error.response.status === 400
                    || error.response.status === 401
                    || error.response.status === 404
                ) {
                    setActiveStateError({
                        name: error.response.data.name,
                        message: error.response.data.message,
                        status: error.response.status
                    })
                } else {
                    setActiveStateError({
                        name: "system error",
                        message: "Please, try again after few moments. If you still can’t get the result your are looking for, contact your administrator."
                    })
                }
            }
            else if (error.request) {
                setActiveStateError({
                    name: "network error",
                    message: "Please, make sure you are connected to the network. If you still can’t get the result your are looking for, contact your administrator."
                })
            } else {
                setActiveStateError({
                    name: "unknown error",
                    message: "An unknown error occurred, please contact your administrator."
                })
            }

            setIsActiveStateLoading(false);
            setActiveStateData([]);
            setisDeactivate(false)
        }
    }

    useEffect(() => {
        fetchData();
    }, [isDeactivate]);

    return {
        activeStateData,
        activeStateError,
        isActiveStateLoading
    };
}

export default useFetchActiveState;