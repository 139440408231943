import { useEffect, useRef, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { addDays, format } from 'date-fns';

import "./report-header.styles.scss";
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

import ButtonSpinner from "../button-spinner/button-spinner.compoenent";
import DetailRefresh from "../detail-refresh/detail-refresh.component";
import DropDownButton from "../drop-down-button/drop-down-button.componenet";
import TitleHeader from "../title-header/title-header.componenet";
import ReportExport from "../report-export/report-export.compoenent";
import ReportPrint from "../report-print/report-print.compoenent";
import ReportTimeframeSelect from "../report-timeframe-select/report-timeframe-select.componenet";

import DateRange from "../date-range/date-range.component";
import { DateRangePicker, createStaticRanges } from 'react-date-range';
import ReportDateRangeButton from "../report-date-range-button/report-date-range-button.compoenent";
import CustomButton from "../custom-button/custom-button.componenet";
import { setExpenseType, setLocationFilter, setPaymentMethod, setPaymentStatus, setReportDateRange } from "../../redux/report/report.actions";
import { connect } from "react-redux";
import { getThisWeek } from "../../utils/date.utils";
import { selectExpenseType, selectLoadedFilter, selectLocationFilter, selectPaymentMethod, selectPaymentStatus, selectReportDateRange, selectReportInfo } from "../../redux/report/report.reselect";
import { createStructuredSelector } from "reselect";
import DateRangeHolder from "../date-range-holder/date-range-holder.component";
import SelectFilter from "../select-filter/select-filter.componenet";
import { authTokenSelector } from "../../redux/user/user.reselect";
import ListGlobalFilter from "../list-global-filter/list-global-filter.component";

const ReportHeader = ({
    handleSideNav,
    reportTitle,
    authToken,
    isLoading,
    hasNoDate,
    setReportDateRange,
    setExpenseType,
    setPaymentMethod,
    setPaymentStatus,
    endpoint,
    reportInfo,
    setLocationFilter,
    locationFilter,
    paymentStatus,
    paymentMethod,
    expenseType,
    reportDateRange
}) => {
    const reportHeaderRef = useRef(null);
    const [isSticky, setIsSticky] = useState(false);
    const [isMobile, setIsMobile] = useState(false);

    const methods = useForm({
        shouldUnregister: false
    });

    const setStickHeader = () => {
        const scrollTop = window.scrollY;
        if (scrollTop > 95) {
            return setIsSticky(true);
        }

        setIsSticky(false)
    }

    const setWindowSize = () => {
        const windowWidth = window.innerWidth;
        if (windowWidth < 991) {
            return setIsMobile(true)
        }

        setIsMobile(false)
    }

    useEffect(() => {
        window.addEventListener("scroll", setStickHeader);
        window.addEventListener("resize", setWindowSize);

        return () => {
            window.removeEventListener("scroll", setStickHeader);
            window.removeEventListener("scroll", setWindowSize);
        }
    }, []);

    useEffect(() => {
        const windowWidth = window.innerWidth;
        if (windowWidth < 991) {
            return setIsMobile(true)
        }

        setIsMobile(false)
    }, [])

    useEffect(() => {
        if (locationFilter) {
            methods.setValue("branch", locationFilter);
        }

        if (paymentStatus) {
            methods.setValue("payemntStatus", paymentStatus);
        }

        if (expenseType) {
            methods.setValue("expenseType", expenseType);
        }

        if (paymentMethod) {
            methods.setValue("payemntMethod", paymentMethod);
        }

    }, [locationFilter, paymentStatus, paymentMethod, expenseType, methods]);


    const handleOnChangeSelect = (type, value) => {
        if (type === "paymentMethod") {
            setPaymentMethod(value)
        } else if (type === "paymentStatus") {
            setPaymentStatus(value)
        } else if (type === "expenseType") {
            setExpenseType(value)
        } else if (type === "location") {
            setLocationFilter(value)
        }
    }

    return (
        <div className={`report-header  ${isSticky ? "report-header--sticky" : null}`} ref={reportHeaderRef}>
            <TitleHeader
                hamburger={false}
                handleSideNav={handleSideNav}
            >
                {reportTitle}
            </TitleHeader>
            <div className="report-header__tools">
                <div className='report-header__spinner'>
                    {
                        isLoading &&
                        <ButtonSpinner />
                    }
                </div>
                <div className={`report-header__tools__actions  report-header__tools__actions--bottom-margin-70`}>
                    {reportInfo.hasSearch &&
                        <div className="report-header__tools__actions__search">
                            <ListGlobalFilter
                                endpoint={endpoint}
                            />
                        </div>
                    }
                    {
                        !isMobile &&
                        <>
                            {
                                !reportInfo.isNoExportAndPrint &&
                                <>
                                    <ReportExport
                                        DropDownButton={DropDownButton}
                                    />
                                    <ReportPrint
                                        DropDownButton={DropDownButton}
                                    />
                                </>
                            }

                            <DetailRefresh
                                DropDownButton={DropDownButton}
                            />
                        </>
                    }
                    <div className={`report-header__tools__actions__filters`}>
                        {<FormProvider {...methods}>
                            {reportInfo.hasExpenseTypeFilter &&
                                <SelectFilter
                                    fieldItemName={"expenseType"}
                                    placeholder={"Expense Type"}
                                    authToken={authToken}
                                    endpointUrl={"/expensetypes"}
                                    handleOnChangeSelect={(value) => { handleOnChangeSelect("expenseType", value) }}
                                    allOption={{ value: null, label: "All Expense Types" }}
                                />}
                            {reportInfo.hasPaymentMethodFilter &&
                                <SelectFilter
                                    fieldItemName={"payemntMethod"}
                                    placeholder={"Payment Method"}
                                    authToken={authToken}
                                    endpointUrl={"/paymentmethods"}
                                    handleOnChangeSelect={(value) => { handleOnChangeSelect("paymentMethod", value) }}
                                    allOption={{ value: null, label: "All Payment Methods" }}
                                />}
                            {reportInfo.hasPaymentStatusFilter &&
                                <SelectFilter
                                    fieldItemName={"payemntStatus"}
                                    placeholder={"Payment Status"}
                                    authToken={authToken}
                                    handleOnChangeSelect={(value) => { handleOnChangeSelect("paymentStatus", value) }}
                                    allOption={{ value: null, label: "All Payment Statuses" }}
                                    defaultOptions={[
                                        { value: 1, label: "Paid" },
                                        { value: 2, label: "Unpaid" },
                                        { value: 3, label: "Partial Paid" },
                                        { value: 4, label: "Unpaid & Partial Paid" },
                                        { value: 5, label: "Owing" },
                                    ]}
                                />}
                            {reportInfo.hasLocationFilter &&
                                <SelectFilter
                                    fieldItemName={"branch"}
                                    placeholder={"Branch"}
                                    authToken={authToken}
                                    endpointUrl={"/locations"}
                                    handleOnChangeSelect={(value) => { handleOnChangeSelect("location", value) }}
                                    allOption={{ value: null, label: "All Branches" }}
                                />}
                        </FormProvider>
                        }
                    </div>
                    {
                        reportInfo.hasDateRange &&
                        <DateRangeHolder />
                    }
                </div>
            </div>
        </div>
    )
}
const mapStateToProps = createStructuredSelector({
    authToken: authTokenSelector,
    reportInfo: selectReportInfo,
    locationFilter: selectLocationFilter,
    paymentStatus: selectPaymentStatus,
    paymentMethod: selectPaymentMethod,
    expenseType: selectExpenseType,
    reportDateRange: selectReportDateRange
});

const mapDispatchToProps = (dispatch) => ({
    setReportDateRange: (data) =>
        dispatch(setReportDateRange(data)),
    setExpenseType: (data) =>
        dispatch(setExpenseType(data)),
    setPaymentMethod: (data) =>
        dispatch(setPaymentMethod(data)),
    setPaymentStatus: (data) =>
        dispatch(setPaymentStatus(data)),
    setLocationFilter: (data) =>
        dispatch(setLocationFilter(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportHeader)