import React, { useEffect } from 'react';

import { createStructuredSelector } from 'reselect';
import { selectModalFormTemplate, selectModalTitle, selectShowAddSupplierModal, selectShowSupplierModal } from '../../redux/modal/modal.reselect';
import { connect } from 'react-redux';
import ADD_SUPPLIER_FORM_TEMPLATE from './add-supplier.template.js';
import { setModalTemplate, setModalType, setShowAddSupplierModal } from '../../redux/modal/modal.actions';

import ModalContainer from '../modal/modal.container';

const AddSupplier = ({
    showAddSupplier,
    setModalTemplate,
    setModalType,
    formTemplate,
    setShowAddSupplierModal
}) => {
    useEffect(() => {
        setModalType("ADD_SUPPLIER")
        setModalTemplate(ADD_SUPPLIER_FORM_TEMPLATE);
    }, []);
    
    return (
        <div className="add_supplier">
            {
                Object.keys(formTemplate).length > 0 ?
                    <ModalContainer
                        showModal={showAddSupplier}
                        setShowModal={setShowAddSupplierModal}
                        size={"LARGE"}
                    /> 
                    : null
            }
        </div>
    )
}

const mapStateToProps = createStructuredSelector({
    showAddSupplier: selectShowAddSupplierModal,
    formTemplate: selectModalFormTemplate
})

const mapDispatchToProps = (disptach) => ({
    setShowAddSupplierModal: () =>
        disptach(setShowAddSupplierModal()),
    setModalTemplate: (formTemplate) =>
        disptach(setModalTemplate(formTemplate)),
    setModalType: (modalType) =>
        disptach(setModalType(modalType))
})

export default connect(mapStateToProps, mapDispatchToProps)(AddSupplier);