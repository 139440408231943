export const SALES_ORDER_DOCUMENT_DROP_DOWN_ITEMS = [
    {
        id: "sales-orders",
        text: "Pro forma"
    },
    {
        id: "invoices",
        text: "Invoice"
    },
    {
        id: "receipts",
        text: "Receipt"
    },
    {
        id: "picks",
        text: "Pick list"
    },
    {
        id: "packing-lists",
        text: "Packing list"
    },
    {
        id: "packing-slips",
        text: "Packing slip"
    },
    {
        id: "box-labels",
        text: "Box label"
    }
]