const MODAL_TYPE = {
    ADD_SUPPLIER: "ADD_SUPPLIER",
    EDIT_SUPPLIER: "EDIT_SUPPLIER",
    ADD_SUPPLIER_GROUP: "ADD_SUPPLIER_GROUP",
    EDIT_SUPPLIER_GROUP: "EDIT_SUPPLIER_GROUP",
    ADD_ITEM_GROUP: "ADD_ITEM_GROUP",
    EDIT_ITEM_GROUP: "EDIT_ITEM_GROUP",
    ADD_ITEM_TAX: "ADD_ITEM_TAX",
    EDIT_ITEM_TAX: "EDIT_ITEM_TAX",
    ADD_UNIT_OF_MEASURE: "ADD_UNIT_OF_MEASURE",
    EDIT_UNIT_OF_MEASURE: "EDIT_UNIT_OF_MEASURE",
    ADD_ITEM: "ADD_ITEM",
    EDIT_ITEM: "EDIT_ITEM",
    ADD_CUSTOMER_GROUP: "ADD_CUSTOMER_GROUP",
    EDIT_CUSTOMER_GROUP: "EDIT_CUSTOMER_GROUP",
    ADD_LOCATION: "ADD_LOCATION",
    EDIT_LOCATION: "EDIT_LOCATION",
    ADD_CUSTOMER: "ADD_CUSTOMER",
    EDIT_CUSTOMER: "EDIT_CUSTOMER",
    STOCK_ENTRY: "STOCK_ENTRY"
}

export default MODAL_TYPE;