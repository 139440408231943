import { format } from 'date-fns';
import FormTableCalculateDisplay from '../../../components/form-table-calculate/form-table-calculate.component';
import FormTableHeaderCell from '../../../components/form-table-header-cell/form-table-header-cell.component';
import FormTableInlineDelete from '../../../components/form-table-inline-delete/form-table-inline-delete.component';
import FormTableSelect from '../../../components/form-table-select/form-table-select.component';
import FormTableTextField from '../../../components/form-table-text-field/form-table-text-field.component';
import { useMediaQuery } from 'react-responsive';

export const ITEM_INFO = {
  formTitle: "Product",
  formTitleForNew: "New Product",
  path: "/items",
  listPage: "/items",
}

export const ITEM_FORM_TEMPLATE = {
  bodySections: {
    basic: {
      sectionType: "basic",
      sectionTypeId: "BASIC",
      expandable: false,
      breakPoint: 2,
      fieldName: "item",
      fieldItems: [
        {
          label: "Product Code",
          type: "text",
          name: "itemCode",
          initialFocus: false,
          validationProps: {
            required: "Product Code is required"
          }
        },
        {
          label: "Product Name",
          type: "text",
          name: "itemName",
          initialFocus: false,
          validationProps: {
            required: "Product name 1 is required"
          }
        },
        {
          label: "Unit of Measure",
          type: "select",
          name: "UnitOfMeasure",
          required: false,
          initialFocus: false,
          muliselect: false,
          url: "/unitofmeasures",
          validationProps: {
            required: "Unit of measure is required"
          }
        },
        {
          label: "Product Type",
          type: "select",
          name: "ItemType",
          required: false,
          initialFocus: false,
          muliselect: false,
          hasDefaultValue: true,
          defaultValue: { label: 'Stocked Product', value: 1 },
          url: "/itemtypes",
          validationProps: {
            required: "Product Type is required"
          }
        }
      ]
    },
    stockDetails: {
      sectionType: "Stock",
      sectionTypeId: "stockDetails",
      fieldName: "stockDetails",
      expandable: true,
      isExpand: true,
      stockDetail: true,
      showEditOnly: true,
      fieldItems: [{}]
    },
    tableForm: {
      sectionType: "Beginning Quantities",
      sectionTypeId: "tableForm",
      fieldName: "items",
      expandable: true,
      isExpand: true,
      hasNoAction: false,
      hasProgress: false,
      hasAutoFillButton: false,
      tableColumns: [
        {
          Header: () => {
            return (
              <FormTableHeaderCell
                text={"Branch"}
                required={false}
              />
            )
          },
          accessor: "location",
          width: 160,
          Cell: ({ row }) => {
            const endpointUrl = "/locations";

            const isMobileView = useMediaQuery({ maxWidth: 991 });
          
            return (<FormTableSelect
              fieldName={"items"}
              fieldItemName={"Location"}
              rowNumber={row.id}
              value={row.value}
              endpointUrl={endpointUrl}
              validationProps={false}
              disabled={row.isDisable}
              headerName={"Branch"}
              isMobileView={isMobileView}
            />)
          }
        },
        {
          Header: () => {
            return (
              <FormTableHeaderCell
                text={"Beginning Quantity"}
                required={false}
              />
            )
          },
          accessor: "beginningQty",
          width: 160,
          Cell: ({ row }) => {
            const isMobileView = useMediaQuery({ maxWidth: 991 });

            return (<FormTableTextField
              fieldName={"items"}
              fieldItemName={"beginningQty"}
              rowNumber={row.id}
              valueType={"number"}
              validationProps={false}
              align="right"
              disabled={row.isDisable}
              headerName={"Beginning Quantity"}
              isMobileView={isMobileView}
            />)
          }
        },
        {
          id: "action",
          width: 70,
          Cell: ({ row, toggleRowSelected }) => {
            const uuid = row.original.uuid;
            return (<FormTableInlineDelete
              rowNumber={row.id}
              toggleRowSelected={toggleRowSelected}
            />)
          }
        }
      ]
    },
    priceAndCostDetails: {
      sectionType: "Pricing & Cost",
      sectionTypeId: "itemPriceAndCostDetails",
      multiForm: false,
      mutliFormTitle: "itemPriceAndCost",
      expandable: true,
      isExpand: false,
      addButton: true,
      breakPoint: 2,
      fieldName: "itemPriceAndCost",
      fieldItems: [
        {
          label: "Unit Price (ETB)",
          type: "text",
          name: "unitSellingPrice",
          initialFocus: false,
          required: false,
        },
        {
          label: "Unit Cost (ETB)",
          type: "text",
          name: "unitCost",
          initialFocus: false,
          required: false,
        },
        {
          label: "Beginning Unit Cost (ETB)",
          type: "text",
          name: "beginningUnitCost",
          initialFocus: false,
          required: false,
        },
        {
          label: "Average Unit Cost (ETB)",
          type: "text",
          name: "averageUnitCost",
          initialFocus: false,
          required: false,
          disabled: true
        },
      ]
    },
    itemAdditionalInfoDetails: {
      sectionType: "Additional Information",
      sectionTypeId: "itemAdditionalInfoDetails",
      multiForm: false,
      mutliFormTitle: "itemAdditionalInfo",
      expandable: true,
      isExpand: false,
      addButton: true,
      breakPoint: 3,
      fieldName: "itemAdditionalInfo",
      fieldItems: [
        {
          label: "Category",
          type: "select",
          name: "ItemGroup",
          required: false,
          initialFocus: false,
          muliselect: false,
          url: "/itemgroups",
        },
        {
          label: "Product Name for Purchase",
          type: "text",
          name: "itemName2",
          initialFocus: false,
        },
        {
          label: "Reorder Level",
          type: "text",
          name: "reorderLevel",
          initialFocus: false,
          required: false,
        },
        {
          label: "Expiration Date",
          type: "date",
          name: "expirationDate",
          initialFocus: false,
          initalValueEmpty: true 
        },
        {
          label: "Remark",
          type: "text",
          name: "description",
          initialFocus: false,
          required: false,
        },
      ]
    },
    auditHistoryDetails: {
      sectionType: "Audit History details",
      sectionTypeId: "auditHistoryDetails",
      multiForm: false,
      expandable: true,
      isExpand: false,
      showEditOnly: true, 
      addButton: true,
      position: "LAST",
      breakPoint: 1,
      fieldName: "auditHistory",
      fieldItems: [
        {
          label: "Created By",
          type: "text",
          name: "createdBy",
          initialFocus: false,
          disabled: true
        },
        {
          label: "Updated By",
          type: "text",
          name: "updatedBy",
          initialFocus: false,
          disabled: true
        },
      ]
    },
  },
  watchFields: []
}
