import { useForm, FormProvider } from "react-hook-form";

import "./document-select.styles.scss";

import SelectField from "../select-field/select-field.componenet";
import { useEffect } from "react";
import { connect } from "react-redux";
import { setBoxNumber } from "../../redux/document/document.actions";

const DocumentSelect = ({
    endpoint,
    authToken,
    setBoxNumber
}) => {
    const methods = useForm({
        shouldUnregister: true
    });

    useEffect(() => {
        const watch = methods.watch("boxNumber")
        if (watch) {
            setBoxNumber(watch.value);
        }
    }, [methods.watch()])

    return (
        <div className="document-select">
            <FormProvider {...methods}>
                <form>
                    <SelectField
                        label={false}
                        initialFocus={false}
                        isNotArrayField={true}
                        fieldName={"boxNumber"}
                        authToken={authToken}
                        endpointUrl={endpoint}
                        mutliselect={false}
                        isDefaultValueFromApi={true}
                        disabled={false}
                        isSearchable={false}
                        inverted={true}
                    />
                </form>
            </FormProvider>
        </div>
    )
}

const mapDispatchToProps = (dispatch) => ({
    setBoxNumber: (data) =>
        dispatch(setBoxNumber(data))
})

export default connect(null, mapDispatchToProps)(DocumentSelect);
