import IconPurchasing from "../../assets/icons/purchasing.svg";
import IconInventory from "../../assets/icons/inventory.svg";
import IconSales from "../../assets/icons/sales.svg";
import IconReport from "../../assets/icons/report.svg";

import { FaCartShopping } from "react-icons/fa6";
// import { MdInventory } from "react-icons/md";
import { RiShoppingBag3Fill } from "react-icons/ri";
import { BsFileBarGraphFill } from "react-icons/bs";

import { MdShoppingCart } from "react-icons/md";
import { MdInventory } from "react-icons/md";
import { TbTruckDelivery } from "react-icons/tb";
import { LuRefreshCcwDot } from "react-icons/lu";
import { MdFactory } from "react-icons/md";
import { FaShoppingBag } from "react-icons/fa";
import { FaUsers } from "react-icons/fa";
import { FaHandshakeSimple } from "react-icons/fa6";
import { HiDocumentReport } from "react-icons/hi";
import { MdOutlineInventory } from "react-icons/md";
import { TbReportAnalytics } from "react-icons/tb";
import { BiSolidShoppingBag } from "react-icons/bi";
import { IoIosCash } from "react-icons/io";
import { RiTodoFill } from "react-icons/ri";

export const navItems1 = [
    {
        title: "Sales",
        icon: FaCartShopping,
        items: [
            {
                text: 'Sales Orders',
                description: "Manage sales and sales payments",
                icon: MdShoppingCart,
                page: '/sales-orders'
            },
            {
                text: 'Customers',
                description: 'Manage customers information',
                icon: FaUsers,
                page: '/customers'
            },
            {
                text: 'Sales Quotes',
                description: "Manage sales and sales payments",
                icon: RiTodoFill,
                page: '/sales-quotes'
            },
        ]
    },
    {
        title: "Stock",
        icon: MdInventory,
        items: [
            {
                text: 'Products',
                description: 'Track stock levels for each product',
                icon: MdInventory,
                page: '/items'
            },
            {
                text: 'Stock Transfers',
                description: 'Transfer stock between branches',
                icon: TbTruckDelivery,
                page: '/stock-transfers'
            },
            {
                text: 'Stock Adjustments',
                description: 'Make adjustments to stock levels',
                icon: LuRefreshCcwDot,
                page: '/stock-adjustments'
            },
            {
                text: 'Work Orders',
                description: 'Oversee manufacturing processes',
                icon: MdFactory,
                page: '/work-orders'
            },
        ]
    },
    {
        title: "Purchasing",
        icon: RiShoppingBag3Fill,
        items: [
            {
                text: 'Purchase Orders',
                description: 'Manage purchases and receiving',
                icon: BiSolidShoppingBag,
                page: '/purchase-orders'
            },
            {
                text: 'Suppliers',
                description: 'Manage suppliers information',
                icon: FaHandshakeSimple,
                page: '/suppliers'
            },
            {
                text: 'Operating Expense',
                description: 'Recored operating expenses',
                icon: IoIosCash,
                page: '/operating-expenses'
            },
        ]
    },
    {
        title: "Report",
        icon: BsFileBarGraphFill,
        items: [
            {
                text: 'Sales Report',
                description: 'Generate reports for sales',
                icon: HiDocumentReport,
                page: '/sales-reports'
            },
            {
                text: 'Stock Report',
                description: 'Retrieve stock reports',
                icon: MdOutlineInventory,
                page: '/inventory-reports'
            },
            {
                text: 'Purchasing Report',
                description: 'Obtain reports on purchases',
                icon: TbReportAnalytics,
                page: '/purchase-reports'
            }
        ]
    }
]




