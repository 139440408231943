import { format } from 'date-fns';
import ListActions from '../../../components/list-actions/list-actions.componenet';
import ListTableMainCell from '../../../components/list-table-main-cell/list-table-main-cell.component';
import TableCellStatus from '../../../components/table-cell-status/table-cell-status.component';

import { displayCellValueWithPercentile } from '../../../utils/column-values.util';

const ENDPOINT = "/item_taxes";

export const ITEM_TAXES_COLUMNS = [
    {
        Header: "Item Tax",
        accessor: "itemTax",
        style: {
            position: "sticky",
            left:0,
            top:0,
            zIndex: 2
        }
    },
    {
        Header: "Tax Rate",
        accessor: "taxRate",
        Cell: ({ value }) => {
            return displayCellValueWithPercentile(value);
        }
    }
]