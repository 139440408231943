import React from "react";

import TitleHeader from "../title-header/title-header.componenet";
import CustomButton from "../custom-button/custom-button.componenet";
import lodash from 'lodash';
import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import { selectFormEdit, selectFormTemplate, selectHasApprovalRight, selectIsApprove, selectIsComplete, selectIsLoading, selectIsPay } from "../../redux/form/form.reselect";
import ButtonSpinner from "../button-spinner/button-spinner.compoenent";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import "./form-header.styles.scss";

import DropDownButton from "../drop-down-button/drop-down-button.componenet";
import DetailRefresh from "../detail-refresh/detail-refresh.component";
import DetailPrintView from "../detail-print-view/detail-print-view.component";
import DocumentViewButton from "../document-view-button/document-view-button.component";
import { selectDocumentDropDownItems } from "../../redux/document/document.reselect";
import Deactivate from "../deactivate/deactivate.componenet";
import { setFormForApprove, setFormForComplete, setFormForPay } from "../../redux/form/form.actions";
import { authTokenSelector } from "../../redux/user/user.reselect";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import { selectModalFormTemplate } from "../../redux/modal/modal.reselect";
import { selectEditUUID } from "../../redux/report/report.reselect";

import { FaPlus } from "react-icons/fa6";
import { IoMdAdd } from "react-icons/io";
import IconAdd from "../../assets/icons/add.svg";
import IconList from "../../assets/icons/list.svg";
import { selectDeactivateIsLoading } from "../../redux/deactivate/deactivate.reselect";

const FormHeader = ({
    authToken,
    handleSideNav,
    formTitle,
    editUUID,
    isLoading,
    deactivateIsLoading,
    handleAddNew,
    handleCloseForm,
    handleSave,
    handleComplete,
    handlePay,
    handleApprove,
    handleConvertToOrder,
    formEdit,
    documentDropDownItems,
    isViewOnly,
    isNoDeactivate,
    endpointForQuickComplete,
    endpointForQuickPay,
    endpointForApprove,
    setFormForComplete,
    quickComplete,
    isComplete,
    setFormForPay,
    quickPay,
    approval,
    isPay,
    setFormForApprove,
    isApprove,
    hasApprovalRight,
    convertToOrder,
    noAbalityToAddNew,
    toast
}) => {
    const formHeaderRef = useRef(null);
    const [isSticky, setIsSticky] = useState(false);
    const [isMobile, setIsMobile] = useState(false);

    const urlComplete = `${endpointForQuickComplete}/completestatus`;
    const urlPay = `${endpointForQuickPay}/paystatus`;
    const urlApprove = `${endpointForApprove}/approvestatus`;

    useEffect(() => {
        if (quickComplete) {
            setFormForComplete(authToken, urlComplete);
        }
    }, [isLoading, quickComplete])

    useEffect(() => {
        if (quickPay) {
            setFormForPay(authToken, urlPay);
        }
    }, [isLoading, quickPay])

    useEffect(() => {
        if (approval && formEdit) {
            setFormForApprove(authToken, urlApprove);
        }
    }, [isLoading, approval])

    const setStickHeader = () => {
        const scrollTop = window.scrollY;
        if (scrollTop > 95) {
            return setIsSticky(true);
        }

        setIsSticky(false)
    }

    const setWindowSize = () => {
        const windowWidth = window.innerWidth;
        if (windowWidth < 991) {
            return setIsMobile(true)
        }

        setIsMobile(false)
    }

    useEffect(() => {
        window.addEventListener("scroll", setStickHeader);
        window.addEventListener("resize", setWindowSize);

        return () => {
            window.removeEventListener("scroll", setStickHeader);
            window.removeEventListener("scroll", setWindowSize)
        }
    }, []);

    useEffect(() => {
        const windowWidth = window.innerWidth;
        if (windowWidth < 991) {
            return setIsMobile(true)
        }

        setIsMobile(false)
    }, [])

    return (
        <div className={`form-header  ${isSticky ? "form-header--sticky" : null}`} ref={formHeaderRef}>
            <div className="form-header__title">
                <TitleHeader
                    hamburger={false}
                    handleSideNav={handleSideNav}
                >
                    {formTitle}
                </TitleHeader>
            </div>
            <div className="form-header__tools">
                <div className="form-header__tools__actions">
                    <div className='form-header__spinner'>
                        {
                            isLoading || deactivateIsLoading ?
                                <ButtonSpinner /> : null
                        }
                    </div>
                    {
                        formEdit &&
                        <>
                            {documentDropDownItems.length ? <DocumentViewButton
                                DropDownButton={DropDownButton}
                                noText={isMobile}
                            /> : null}
                            {!isViewOnly && !isNoDeactivate && <Deactivate
                                DropDownButton={DropDownButton}
                                toast={toast}
                            />}
                            <DetailRefresh
                                DropDownButton={DropDownButton}
                            />
                        </>
                    }
                    {!isMobile && !isViewOnly && formEdit && !noAbalityToAddNew &&
                        <CustomButton
                            inverted={true}
                            icon={IoMdAdd}
                            handleClick={(event) => { handleAddNew(event) }}
                        >
                            Add New
                        </CustomButton>
                    }
                    {!isViewOnly && formEdit && (approval || quickPay || quickComplete || convertToOrder) &&
                        <div className="form-header__tools__actions__optionals">
                            <div className="form-header__tools__actions__optionals__items">
                            <div className="form-header__tools__actions__optionals__items__item">
                                    {!isViewOnly && formEdit && convertToOrder &&
                                        <CustomButton
                                            inverted={true}
                                            isLoading={isLoading}
                                            handleClick={(event) => { handleConvertToOrder(event) }}
                                        >
                                              Convert to Order

                                        </CustomButton>
                                    }
                                </div>
                                <div className="form-header__tools__actions__optionals__items__item">
                                    {!isViewOnly && formEdit && approval &&
                                        <CustomButton
                                            inverted={true}
                                            isLoading={isLoading}
                                            hasApprovalRight={!hasApprovalRight}
                                            handleClick={(event) => { handleApprove(event) }}
                                        >
                                            {
                                                hasApprovalRight ?
                                                    isApprove ? "Unapprove" : "Approve"
                                                    :
                                                    isApprove ? "Approved" : "Unapproved"
                                            }

                                        </CustomButton>
                                    }
                                </div>
                                <div className="form-header__tools__actions__optionals__items__item">
                                    {!isViewOnly && quickPay && formEdit &&
                                        <CustomButton
                                            inverted={true}
                                            isLoading={isLoading}
                                            isPay={isPay}
                                            handleClick={(event) => { handlePay(event) }}
                                        >
                                            {
                                                isPay !== null ? lodash.startCase(isPay) : "Paid"
                                            }

                                        </CustomButton>
                                    }
                                </div>
                                <div className="form-header__tools__actions__optionals__items__item">
                                    {!isViewOnly && quickComplete && formEdit &&
                                        <CustomButton
                                            isLoading={isLoading}
                                            isComplete={isComplete}
                                            handleClick={(event) => { handleComplete(event) }}
                                        >
                                            {isComplete ? "Completed" : "Complete"}
                                        </CustomButton>
                                    }
                                </div>
                            </div>
                        </div>
                    }


                    {!isMobile && !formEdit &&
                        <CustomButton
                            inverted={true}
                            handleClick={(event) => { handleCloseForm(event) }}
                        >
                            Cancel
                        </CustomButton>
                    }
                    {!isViewOnly &&
                        <CustomButton
                            isLoading={isLoading}
                            handleClick={(event) => { handleSave(event) }}
                        >
                            Save
                        </CustomButton>
                    }

                </div>
            </div>
        </div >
    )
}
const mapStateToProps = createStructuredSelector({
    authToken: authTokenSelector,
    isLoading: selectIsLoading,
    formEdit: selectFormEdit,
    editUUID: selectEditUUID,
    documentDropDownItems: selectDocumentDropDownItems,
    isComplete: selectIsComplete,
    isPay: selectIsPay,
    isApprove: selectIsApprove,
    hasApprovalRight: selectHasApprovalRight,
    deactivateIsLoading: selectDeactivateIsLoading
});

const mapDispatchToProps = (dispatch) => ({
    setFormForComplete: (authToken, url) =>
        dispatch(setFormForComplete(authToken, url)),
    setFormForPay: (authToken, url) =>
        dispatch(setFormForPay(authToken, url)),
    setFormForApprove: (authToken, url) =>
        dispatch(setFormForApprove(authToken, url))
})

export default connect(mapStateToProps, mapDispatchToProps)(FormHeader);
