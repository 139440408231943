export const LOCAL_FILTER_FIELDS = [
    {
        label: "Employee Code",
        type: "text",
        name: "employeeCode",
    },
    {
        label: "First Name",
        type: "text",
        name: "firstName",
    }
]