import React, { useEffect } from 'react';

import './modal-body-section.styles.scss';

import IconExpand from '../../assets/icons/expand-arrow-modal.svg';
import FormTextarea from '../form-textarea/form-textarea.componenet';
import SelectField from '../select-field/select-field.componenet';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useFormContext, useFieldArray } from 'react-hook-form';
import { selectModalEdit, selectModalExpandSection, selectModalFormValue } from '../../redux/modal/modal.reselect';
import { addMorePart, removePart, setIsModalFetching, setModalExpandSection } from '../../redux/modal/modal.actions';

import FormField from '../form-field/form-field.component';
import AddRemoveButton from '../add-remove-button/add-remove-button.componenet';
import { authTokenSelector } from '../../redux/user/user.reselect';

const ModalBodySection = ({
    modalExpandSection,
    setModalExpandSection,
    setModalFieldValue,
    modalFormValues,
    addMorePart,
    removePart,
    setIsModalFetching,
    authToken,
    focusFieldName,
    bodySection: {
        sectionType,
        sectionTypeId,
        fieldName,
        expandable,
        isExpand,
        fieldItems,
        addButton,
        multiForm,
        mutliFormTitle,
        position,
        breakPoint
    }
}) => {
    const { fields, append, remove } = useFieldArray({
        name: fieldName
    });

    let partNumber = null;
    const part = "part";
    const handleChange = (event, partNumber) => {
        setModalFieldValue(
            sectionTypeId,
            part + (partNumber),
            event.target.name,
            event.target.value
        )
    }
    const handleSelectChange = (value, action, partNumber) => {
        setModalFieldValue(
            sectionTypeId, part + (partNumber),
            action.name,
            value
        )
    }
    const handleSectionExpand = (event) => {
        event.preventDefault();
        setModalExpandSection(event.target.dataset.sectiontypeid);
    }
    const handleAddMore = (event) => {
        event.preventDefault();
        addMorePart(sectionTypeId, fields[0]);
    }
    const handleRemove = (event) => {
        event.preventDefault();
        removePart(sectionTypeId, part + (partNumber));
    }
    const renderForm = (field, partNumber) => {
        switch (field.type) {
            case 'text':
            case 'email':
                return (
                    <FormField
                        label={field.label}
                        type={field.type}
                        initialFocus={field.initialFocus}
                        validationProps={field.validationProps}
                        fieldName={fieldName}
                        partNumber={partNumber}
                        fieldItemName={field.name}
                    />)
            case 'textarea':
                return (
                    <FormTextarea
                        label={field.label}
                        type={field.type}
                        initialFocus={field.initialFocus}
                        validationProps={field.validationProps}
                        fieldName={fieldName}
                        partNumber={partNumber}
                        fieldItemName={field.name}
                    />
                )
            case 'select':
                return (
                    <SelectField
                        label={field.label}
                        type={field.type}
                        required={field.required}
                        initialFocus={field.initialFocus}
                        validationProps={field.validationProps}
                        fieldName={fieldName}
                        partNumber={partNumber}
                        fieldItemName={field.name}
                        authToken={authToken}
                        endpointUrl={field.url}
                        mutliselect={field.muliselect}
                    />
                )
            default:
                break;
        }
    }

    useEffect(() => {
        append({},  {
            focusName: focusFieldName
          });
    }, [])

    useEffect(() => {
        setIsModalFetching(false)
    }, [])

    useEffect(() => {
        if(isExpand){
            setModalExpandSection(sectionTypeId)
        }
    },[])
    
    return (
        <div
            className={`modal-body-section ${sectionType !== 'basic' ?
                'modal-body-section--margin-top-20' :
                null}
                ${position === 'LAST' ?
                    'modal-body-section--last' :
                    null}
                `}
        >
            {
                sectionType !== 'basic' ?
                    <div className="modal-body-section__header">
                        {expandable ? <button
                            className="modal-body-section__header__title-action"
                            data-sectiontypeid={sectionTypeId}
                            id={sectionTypeId}
                            onClick={handleSectionExpand}>
                            <h5 className="modal-body-section__header__title-action__title">{sectionType}</h5>
                            <img
                                className={`modal-body-section__header__title-action__img
                                        ${modalExpandSection[sectionTypeId] ?
                                        'modal-body-section__header__title-action__img--expand' :
                                        null}`}
                                src={IconExpand} alt="expand icon" />
                        </button> :
                            null
                        }
                    </div> :
                    null
            }
            <div className={`modal-body-section__sub-section
                    ${sectionType !== 'basic' ?
                    modalExpandSection[sectionTypeId] ?
                        'modal-body-section__sub-section--expand' :
                        'modal-body-section__sub-section--collapse' :
                    null}`}
            >
                <div className="modal-body-section__body">
                    {
                        fields.map((field, index) => {
                            partNumber = index;

                            const fieldLength = fieldItems.length;
                            const col1Fields = fieldItems.slice(0, breakPoint)
                            const col2Fields = fieldItems.slice(breakPoint, fieldLength + 1)
                            
                            return (
                                <div key={field.id} >
                                    {
                                        index > 0 &&
                                        <div className="modal-body-section__body__parts__separator" />
                                    }
                                    {
                                        multiForm === true ?
                                            <div className="modal-body-section__body__sub-header">
                                                <h6 className="modal-body-section__body__sub-header__title">{mutliFormTitle} {partNumber + 1}</h6>
                                            </div> :
                                            null
                                    }
                                    <div className='container'>
                                        <div className="row modal-body-section__body__parts">
                                            <div className={`${sectionType === 'basic' ? 'col-md-12' : 'col-md-6'}`}>
                                                <div className="row">
                                                    {col1Fields.map((fieldItem, fieldIndex) => (
                                                        <div
                                                            className={`modal-body-section__body__parts__item  ${fieldItem.size === 'small' ?
                                                                'col-6  col-md-6' :
                                                                'col-md-12'
                                                                }`}
                                                            key={fieldItem.name}
                                                        >
                                                            {renderForm(fieldItem, partNumber)}
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                            <div className={`${sectionType === 'basic' ? 'col-md-12' : 'col-md-6'}`}>
                                                <div className="row" >
                                                    {col2Fields.map((fieldItem, fieldIndex) => (
                                                        <div
                                                            className={`modal-body-section__body__parts__item  ${fieldItem.size === 'small' ?
                                                                'col-6  col-md-6' :
                                                                'col-md-12'
                                                                }`}
                                                            key={fieldItem.name}
                                                        >
                                                            {renderForm(fieldItem, partNumber)}
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        console.log("part number for fields:" + partNumber)
                                    }
                                    <div className="modal-body-section__body__buttons">
                                        {
                                            partNumber > 0 && <AddRemoveButton
                                                handleClick={(event) => { event.preventDefault(); remove(index); }}
                                                type="remove"
                                            > Remove </AddRemoveButton>
                                        }
                                    </div>
                                </div>)
                        })
                    }
                </div>
                {
                    multiForm === true ?
                        <div className={`row modal-body-section__footer`}>
                            {
                                addButton && <AddRemoveButton
                                    handleClick={(event) => { event.preventDefault(); append({})}}
                                    type="add"
                                > Add </AddRemoveButton>
                            }
                        </div> :
                        false
                }

            </div>
        </div>
    )
}

const mapStateToProps = createStructuredSelector({
    modalExpandSection: selectModalExpandSection,
    modalFormValues: selectModalFormValue,
    authToken: authTokenSelector,
});

const mapDispatchToProps = (dispatch) => ({
    setModalExpandSection: (sectionTypeId) =>
        dispatch(setModalExpandSection(sectionTypeId)),
    addMorePart: (sectionTypeId, addedField) =>
        dispatch(addMorePart(sectionTypeId, addedField)),
    removePart: (sectionTypeId, partNumber) =>
        dispatch(removePart(sectionTypeId, partNumber)),
    setIsModalFetching: (isFetching) =>
        dispatch(setIsModalFetching(isFetching))
})

export default connect(mapStateToProps, mapDispatchToProps)(ModalBodySection);