import { format } from 'date-fns';
import TableCellStatus from '../../components/table-cell-status/table-cell-status.component';
import { displayCellValue, displayCellValueNumberToTwoDecimal } from '../../utils/column-values.util';
import { useMediaQuery } from 'react-responsive';
import { stringToNumber } from '../../utils/format-number.util';

export const PURCHASE_REPORT_PAYMENT_DETAILS_INFO = {
    reportTitle: "Purchasing Reports",
    path: "/purchasereports",
    subPath: "/purchasepaymentdetails",
    listPage: "/purchase-reports",
    pagePath:"purchase-orders",
    hasDateRange: true,
    hasChart: true,
    tableHasGrandTotal: true,
    hasPaymentMethodFilter: true,
    hasLocationFilter: false,
    hasSearch: true
}

export const PURCHASE_REPORT_PAYMENT_DETAILS_COLUMN_TEMPLATE = [
    {
        Header: "Payment Date",
        accessor: "paymentDate",
        Footer: 'Grand Total'
    },
    {
        Header: "PO Number",
        accessor: "purchaseOrderNumber",
    },
    {
        Header: "Supplier",
        accessor: "supplierName",
        Cell: ({ value }) => {
            return displayCellValue(value);
        }
    },
    {
        Header: "Payment Method",
        accessor: "paymentMethod",
        Cell: ({ value }) => {
            return displayCellValue(value);
        }
    },
    {
        Header: "Remark",
        accessor: "remark",
        Cell: ({ value }) => {
            return displayCellValue(value);
        }
    },
    {
        Header: "Paid Amount (ETB)",
        accessor: "paidAmount",
        Cell: ({ value }) => {
            const isMobileView = useMediaQuery({ maxWidth: 991 });
            const isRightAligned = true;
            
            let newValue = {}
            if(stringToNumber(value) >= 0){
                 newValue = { value: 3, label: isMobileView ? `${value ? 
                    displayCellValueNumberToTwoDecimal(value) : 0} ETB Paid` :  `${value ? displayCellValueNumberToTwoDecimal(value) : 0}`} 
            } else {
                 newValue = { value: 1, label: isMobileView ? `${value ? 
                    displayCellValueNumberToTwoDecimal(value) : 0} ETB Refund` :  `${value ? displayCellValueNumberToTwoDecimal(value) : 0}`} 
            }

            return (
              <div className={isMobileView && isRightAligned ?
                'report-table__body__row__cell--right-aligned-mobile-cell' : ''}>
                <TableCellStatus
                  value={newValue}
                  noDot={true}
                />
              </div>
            )
        },
        Footer: 'totalPaidAmount'
    }
]
