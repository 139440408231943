import {createSelector} from 'reselect'

export const userSelector = state => state.user;

export const currentUserSelector = createSelector(
    [userSelector],
    user => user.currentUser
);

export const isLoggedInSelector = createSelector(
    [userSelector],
    user => user.isLoggedIn
);

export const authTokenSelector = createSelector(
    [currentUserSelector],
    currentUser => currentUser.authToken
);

export const selectAccessRights = createSelector(
    [userSelector],
    user => user.accessRights
);

export const selectUser = createSelector(
    [currentUserSelector],
    currentUser => currentUser.user
);