
import { useState, useEffect, useReducer } from "react";
import { Axios } from "../config";
import { getAuthToken } from '../utils/auth_token.utils'
import { useHistory, withRouter } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setAccessRights } from "../redux/user/user.actions";

const useAccessRight = (
    endpoint,
    authTokenFromState,
    application,

) => {
    const [accessRightData, setAccessRightData] = useState([]);
    const [accessRightError, setAccessRightError] = useState(null);
    const [isAccessRightLoading, setIsAccessRightLoading] = useState(false);

    const history = useHistory();
    const dispatch = useDispatch()

    const tokenHeader = getAuthToken(authTokenFromState);
    const fetchData = async () => {
        try {
            setIsAccessRightLoading(true);
            const response = await Axios.get(endpoint, {
                headers: tokenHeader
            })

            dispatch(setAccessRights(response.data))
            setAccessRightData(response.data);
            setIsAccessRightLoading(false);
        } catch (error) {
            if (error.response) {
                if (error.response.status === 400
                    || error.response.status === 401
                    || error.response.status === 404
                ) {
                    setAccessRightError({
                        name: error.response.data.name,
                        message: error.response.data.message,
                        status: error.response.status
                    })
                } else {
                    setAccessRightError({
                        name: "system error",
                        message: "Please, try again after few moments. If you still can’t get the result your are looking for, contact your administrator."
                    })
                }
            }
            else if (error.request) {
                setAccessRightError({
                    name: "network error",
                    message: "Please, make sure you are connected to the network. If you still can’t get the result your are looking for, contact your administrator."
                })
            }
            else {
                setAccessRightError({
                    name: "unknown error",
                    message: "An unknown error occurred, please contact your administrator."
                })
            }

            setIsAccessRightLoading(false);
            setAccessRightData([]);
        }
    }

    useEffect(() => {
        fetchData();
    }, [application]);

    // useEffect(() => {
    //     if (Object.keys(accessRightData).length) {
    //         const accessRight = accessRightData.accessRights.find((accessRight => {
    //             return accessRight.application.toLowerCase() === application.toLowerCase()
    //         }))

    //         if (accessRight.permission.toLowerCase() === "no access") {
    //             console.log("has no access")
    //             history.push("/access-denied")
    //         }
    //     }
    // }, [accessRightData])

    return {
        accessRightData,
        accessRightError,
        isAccessRightLoading
    };
}

export default useAccessRight;