
import { useState, useEffect } from "react";
import { Axios } from "../config";
import { getAuthToken } from '../utils/auth_token.utils'

const useFetchDocument = (
    endpoint,
    authTokenFromState,
    isRefresh,
    boxNumber
) => {
    const [data, setData] = useState([]);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    
    const tokenHeader = getAuthToken(authTokenFromState);
    const fetchData = async () => {
        try{
            const response = await Axios.get(endpoint,{
                headers: tokenHeader
            })

            setData(response.data);
            setIsLoading(false);
        } catch(error){
            if (error.response) {
                if (error.response.status === 400
                    || error.response.status === 401
                    || error.response.status === 404
                ) {
                    setError({
                        name: error.response.data.name,
                        message: error.response.data.message,
                        status: error.response.status
                    })
                } else {
                    setError({
                        name: "system error",
                        message: "Please, try again after few moments. If you still can’t get the result your are looking for, contact your administrator."
                    })
                }
            }
            else if (error.request) {
                setError({
                    name: "network error",
                    message: "Please, make sure you are connected to the network. If you still can’t get the result your are looking for, contact your administrator."
                })
            }
            else {
                setError({
                    name: "unknown error",
                    message: "An unknown error occurred, please contact your administrator."
                })
            }

            setIsLoading(false);
            setData([]);
        }
    }

    useEffect(() => {
        setIsLoading(true)
    }, [isRefresh, boxNumber]);

    useEffect(() => {
        fetchData();
    }, [isRefresh, boxNumber]);

    return {
        data,
        error,
        isLoading
    };
}

export default useFetchDocument;