import { format } from 'date-fns';
import TableCellStatus from '../../components/table-cell-status/table-cell-status.component';
import { displayCellValue, displayCellValueNumberToTwoDecimal } from '../../utils/column-values.util';
import { useMediaQuery } from 'react-responsive';

export const PURCHASE_REPORT_BY_ITEM_INFO = {
  reportTitle: "Purchasing Reports",
  path: "/purchasereports",
  subPath: "/purchasebyitem",
  listPage: "/purchase-reports",
  pagePath:"items",
  hasDateRange: true,
  hasChart: false,
  tableHasGrandTotal: true,
  hasSearch: true
}

export const PURCHASE_REPORT_BY_ITEM_COLUMN_TEMPLATE = [
  {
    Header: "Product Code",
    accessor: "itemCode",
    Cell: ({ value }) => {
      return displayCellValue(value);
    },
    Footer: 'Grand Total'
  },
  {
    Header: "Product Name",
    accessor: "itemName",
    Cell: ({ value }) => {
      return displayCellValue(value);
    },
  },
  {
    Header: "Qty Purchased",
    accessor: "qtyPurchased",
    Cell: ({ value }) => {
      const isMobileView = useMediaQuery({ maxWidth: 991 });
      const isRightAligned = true;

      const newValue = { value: 3, label: isMobileView ? `${value} Purchased` : `${value}` }

      return (
        <div className={isMobileView && isRightAligned ?
          'report-table__body__row__cell--right-aligned-mobile-cell' : ''}>
          <TableCellStatus
            value={newValue}
            noDot={true}
          />
        </div>
      )
    },
    Footer: 'qtyPurchased'
  },
  {
    Header: "Total Cost (ETB)",
    accessor: "totalCost",
    Cell: ({ value }) => {
      const isMobileView = useMediaQuery({ maxWidth: 991 });

      if (isMobileView) {
        return `Total Cost: ${value ? displayCellValueNumberToTwoDecimal(value) : 0} ETB`;
      }

      return displayCellValueNumberToTwoDecimal(value);
    },
    Footer: 'totalCost'
  }
]
