export const getThisMonth = () => {
    const now = new Date();
    const startDate = new Date(now.getFullYear(), now.getMonth(), 1);
    const endDate = new Date(now.getFullYear(), now.getMonth() + 1, 0);

    return {
        startDate,
        endDate
    };
};

export const getThisWeek = () => {
    const now = new Date();
    const day = now.getDay()

    const startDate = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate()-day
    );

    const endDate = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate() + (6 - day)
    )

    return {
        startDate,
        endDate
    }
}

export const getToday = () => {
    const now = new Date();
    
    const startDate = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate()
    );

    const endDate = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate()
    )

    return {
        startDate,
        endDate
    }
}
