import { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import ListTableBody from '../../../components/list-table-body/list-table-body.componenet';
import ListHeader from '../../../components/list-header/list-header.component';

import { STOCK_ADJUSTMENT_COLUMNS } from './stock-adjustments-columns.data';
import { LOCAL_FILTER_FIELDS } from './stock-adjustments-local-filter-filters.data';
import { setListType, setLoadedData, setPagePath } from '../../../redux/list/list.actions';
import { setDetailPagePath } from '../../../redux/detail/detail.actions';
import { setFilterEmpty } from '../../../redux/filter/filter.actions';
import { selectListType } from '../../../redux/list/list.reselect';
import { withRouter } from 'react-router-dom';
import { setIsEditByFullForm } from '../../../redux/form/form.actions';
import { useState } from 'react';
import { setTabTitle } from '../../../utils/tab-title.utils';
import { selectAccessRights } from '../../../redux/user/user.reselect';

const APP_NAME = `stock_adjustment`;

const TYPE = {
    STOCK_ADJUSTMENT: "STOCK_ADJUSTMENT"
}

const StockAdjustmentList = ({
    setShowAddPurchaseOrderModal,
    setListType,
    setLoadedData,
    setModalType,
    setFilterEmpty,
    listType,
    history,
    setIsEditByFullForm,
    setPagePath,
    accessRights
}) => {
    const [confrimLoadedData, setConfirmLoadedData] = useState(false);
    const [isAccessRightChecked, setIsAccessRightCheked] = useState(false);
    const isViewOnly = useRef(false);

    const endpoint = "/stockadjustments";
    const pagePath = "/stock-adjustments"

    const handleAddItem = () => {
        history.push(`${pagePath}/new`);
    }

    useEffect(() => {
        const accessRight = accessRights.find((accessRight => {
            return accessRight.application.toLowerCase() === APP_NAME && APP_NAME.toLowerCase()
        }))

        if (accessRight && (accessRight.permission.toLowerCase() === "view only")) {
            isViewOnly.current = true;
        }

        setIsAccessRightCheked(true)
    }, [])

    useEffect(() => {
        if (listType !== TYPE.STOCK_TRANSFER) {
            setLoadedData({ data: null, skip: null, totalNumItems: 0 });
            setFilterEmpty();
        }

        setListType(TYPE.STOCK_TRANSFER);
        setPagePath(pagePath);
        setIsEditByFullForm(true);
        setConfirmLoadedData(true);
    }, [])

    useEffect(() => {
        return () => {
            setIsEditByFullForm(false);
            setPagePath(null);
        }
    }, [])

    useEffect(() => {
        setTabTitle(`Stock Adjustment`)
    }, [])

    return (
        isAccessRightChecked && confrimLoadedData &&
        <>
            <ListHeader
                buttonAction={handleAddItem}
                buttonActionLabel={"Add Stock Adjustment"}
                endpoint={endpoint}
                title={"Stock Adjustments"}
                isViewOnly={isViewOnly.current}
            />
            <ListTableBody
                tableColumns={STOCK_ADJUSTMENT_COLUMNS}
                localFilterFields={LOCAL_FILTER_FIELDS}
                endpoint={endpoint}
            />
        </>
    )
};

const mapStateToProps = createStructuredSelector({
    listType: selectListType,
    accessRights: selectAccessRights
})

const mapDispatchToProps = (dispatch) => ({
    setListType: (listType) =>
        dispatch(setListType(listType)),
    setLoadedData: (data) =>
        dispatch(setLoadedData(data)),
    setFilterEmpty: () =>
        dispatch(setFilterEmpty()),
    setIsEditByFullForm: (data) =>
        dispatch(setIsEditByFullForm(data)),
    setPagePath: (data) =>
        dispatch(setPagePath(data))
})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(StockAdjustmentList))