import { format } from 'date-fns';
import FormTableCalculateDisplay from '../../../components/form-table-calculate/form-table-calculate.component';
import FormTableHeaderCell from '../../../components/form-table-header-cell/form-table-header-cell.component';
import FormTableInlineDelete from '../../../components/form-table-inline-delete/form-table-inline-delete.component';
import FormTableSelect from '../../../components/form-table-select/form-table-select.component';
import FormTableTextField from '../../../components/form-table-text-field/form-table-text-field.component';

export const WORK_ORDER_INFO = {
  formTitle: "Work Order",
  formTitleForNew: "New Work Order",
  path: "/workorders",
  listPage: "/work-orders",
}

export const WORK_ORDER_FORM_TEMPLATE = {
  approval: true,
  bodySections: {
    basic: {
      sectionType: "basic",
      sectionTypeId: "BASIC",
      expandable: false,
      breakPoint: 3,
      fieldName: "workOrder",
      fieldItems: [
        {
          label: "WO Number",
          type: "text",
          name: "workOrderNumber",
          initialFocus: false,
          disabled: true,
          defaultValue: `WO-`
        },
        {
          label: "Branch",
          type: "select",
          name: "Location",
          initialFocus: false,
          muliselect: false,
          url: "/locations",
          validationProps: {
            required: "Branch is required"
          }
        },
        {
          label: "Assembled By",
          type: "text",
          name: "assembledBy",
          initialFocus: false,
        },
        // {
        //   label: "Assign To",
        //   type: "select",
        //   name: "User",
        //   required: false,
        //   initialFocus: false,
        //   muliselect: false,
        //   url: "/users"
        // },
        {
          label: "WO Date",
          type: "date",
          name: "workOrderDate",
          initialFocus: false,
          validationProps: {
            required: "WO date is required"
          }
        },
        {
          label: "Finish Date",
          type: "date",
          name: "finishDate",
          initialFocus: false,
        },
        {
          label: "Other Costs (ETB)",
          type: "text",
          name: "otherCosts",
          initialFocus: false,
        }
      ]
    },
    finishedProductDetails: {
      sectionType: "Finished Product details",
      sectionTypeId: "finishedProductDetails",
      multiForm: false,
      expandable: true,
      isExpand: true,
      addButton: true,
      breakPoint: 1,
      fieldName: "finishedProduct",
      fieldItems: [
        {
          label: "Product",
          type: "select",
          name: "Item",
          initialFocus: false,
          muliselect: false,
          autofiller: true,
          url: "/items",
          noAvailability: true,
          validationProps: {
            required: "Item is required"
          }
        },
        {
          label: "Quantity",
          type: "text",
          name: "qty",
          initialFocus: false,
          defaultValue: 1
        },
      ]
    },
    tableForm: {
      sectionType: "Component Items",
      sectionTypeId: "tableForm",
      fieldName: "items",
      expandable: true,
      isExpand: true,
      isDisable: false,
      tableColumns: [
        {
          Header: () => {
            return (
              <FormTableHeaderCell
                text={"Component Product Code/Name"}
                required={true}
              />
            )
          },
          accessor: "item",
          width: 200,
          Cell: ({ row }) => {
            const endpointUrl = "/items";
            const validationProps = {
              required: "Item Code is required"
            }

            return (<FormTableSelect
              fieldName={"items"}
              fieldItemName={"Item"}
              rowNumber={row.id}
              endpointUrl={endpointUrl}
              validationProps={validationProps}
              disabled={row.isDisable}
              noAvailability= {true}
              shouldTriggerSelectedField={true}
            />)
          }
        },
        {
          Header: () => {
            return (
              <FormTableHeaderCell
                text={"UoM"}
                required={true}
              />
            )
          },
          accessor: "unitOfMeasure",
          width: 120,
          Cell: ({ row }) => {
            const endpointUrl = "/unitofmeasures";
            const validationProps = {
              required: "UoM is required"
            }

            return (<FormTableSelect
              fieldName={"items"}
              fieldItemName={"UnitOfMeasure"}
              rowNumber={row.id}
              value={row.value}
              endpointUrl={endpointUrl}
              validationProps={validationProps}
              disabled={true}
            />)
          }
        },
        {
          Header: () => {
            return (
              <FormTableHeaderCell
                text={"Qty"}
                required={true}
              />
            )
          },
          accessor: "qty",
          width: 80,
          Cell: ({ row }) => {
            const validationProps = {
              required: "Qty is required"
            }

            return (<FormTableTextField
              fieldName={"items"}
              fieldItemName={"qty"}
              rowNumber={row.id}
              valueType={"number"}
              validationProps={validationProps}
              align="right"
              disabled={row.isDisable}
            />)
          }
        },
        {
          Header: () => {
            return (
              <FormTableHeaderCell
                text={"Unit Cost"}
                required={false}
              />
            )
          },
          accessor: "unitCost",
          width: 160,
          Cell: ({ row }) => {
            const validationProps = {
              required: "Unit Cost is required"
            }

            return (<FormTableTextField
              fieldName={"items"}
              fieldItemName={"unitCost"}
              rowNumber={row.id}
              valueType={"number"}
              validationProps={false}
              align="right"
              disabled={row.isDisable}
            />)
          }
        },
        // {
        //   Header: "Subtotal (ETB)",
        //   accessor: "subTotal",
        //   width: 190,
        //   Cell: ({ row }) => {
        //     return (
        //       <FormTableCalculateDisplay
        //         fieldName={"items"}
        //         rowNumber={row.id}
        //         calculateType="multiply"
        //         valuesToCalculate={["qty", "unitCost"]}
        //       />
        //     )
        //   }
        // },
        {
          id: "action",
          width: 70,
          Cell: ({ row, toggleRowSelected }) => {
            const uuid = row.original.uuid;
            return (<FormTableInlineDelete
              rowNumber={row.id}
              toggleRowSelected={toggleRowSelected}
            />)
          }
        }
      ]
    },
    approvalDetails: {
      sectionType: "Approval details",
      sectionTypeId: "approvalDetails",
      multiForm: false,
      expandable: true,
      isExpand: true,
      showEditOnly: true,
      addButton: true,
      breakPoint: 1,
      fieldName: "approvalStatus",
      fieldItems: [
        {
          label: "Current Approval Status",
          type: "select",
          name: "ApprovalStatus",
          initialFocus: false,
          muliselect: false,
          defaultValue: { label: 'Unapproved', value: 2 },
          url: "/approvalstatus"
        }
      ]
    },
    auditHistoryDetails: {
      sectionType: "Audit History details",
      sectionTypeId: "auditHistoryDetails",
      multiForm: false,
      expandable: true,
      isExpand: false,
      showEditOnly: true, 
      addButton: true,
      position: "LAST",
      breakPoint: 2,
      fieldName: "auditHistory",
      fieldItems: [
        {
          label: "Created By",
          type: "text",
          name: "createdBy",
          initialFocus: false,
          disabled: true
        },
        {
          label: "Updated By",
          type: "text",
          name: "updatedBy",
          initialFocus: false,
          disabled: true
        },
        {
          label: "Approved By",
          type: "text",
          name: "approvedBy",
          initialFocus: false,
          disabled: true
        },
      ]
    },
  },
  watchFields: [
    "finishedProduct.0.Item",
    "finishedProduct.0.qty",
  ]
}
