import { format } from 'date-fns';
import FormTableCalculateDisplay from '../../../components/form-table-calculate/form-table-calculate.component';
import FormTableHeaderCell from '../../../components/form-table-header-cell/form-table-header-cell.component';
import FormTableInlineDelete from '../../../components/form-table-inline-delete/form-table-inline-delete.component';
import FormTableSelect from '../../../components/form-table-select/form-table-select.component';
import FormTableTextField from '../../../components/form-table-text-field/form-table-text-field.component';
import { useMediaQuery } from 'react-responsive';

export const STOCK_ADJUSTMENT_INFO = {
  formTitle: "Stock Adjustment",
  formTitleForNew: "New Stock Adjustment",
  path: "/stockadjustments",
  listPage: "/stock-adjustments",
}

export const STOCK_ADJUSTMENT_FORM_TEMPLATE = {
  bodySections: {
    basic: {
      sectionType: "basic",
      sectionTypeId: "BASIC",
      expandable: false,
      breakPoint: 3,
      fieldName: "stockAdjustment",
      fieldItems: [
        {
          label: "Adjustment Number",
          type: "text",
          name: "adjustmentNumber",
          initialFocus: false,
          disabled: true,
          defaultValue: `SA-`
        },
        {
          label: "Branch",
          type: "select",
          name: "Location",
          initialFocus: false,
          muliselect: false,
          url: "/locations",
          validationProps: {
            required: "Branch is required"
          }
        },
        {
          label: "Adjustment Date",
          type: "date",
          name: "adjustmentDate",
          initialFocus: false,
          validationProps: {
            required: "Adjustment date is required"
          }
        },
        {
          label: "Reason",
          type: "select",
          name: "Reason",
          initialFocus: false,
          muliselect: false,
          url: "/reasons",
        },
        {
          label: "Remark",
          type: "text",
          name: "description",
          initialFocus: false,
          required: false,
        },
      ]
    },
    approvalDetails: {
      sectionType: "Approval details",
      sectionTypeId: "approvalDetails",
      multiForm: false,
      expandable: true,
      isExpand: true,
      showEditOnly: true,
      addButton: true,
      breakPoint: 1,
      fieldName: "approvalStatus",
      fieldItems: [
        {
          label: "Current Approval Status",
          type: "select",
          name: "ApprovalStatus",
          initialFocus: false,
          muliselect: false,
          defaultValue: { label: 'Unapproved', value: 2 },
          url: "/approvalstatus"
        }
      ]
    },
    tableForm: {
      sectionType: "Adjustment Items Details",
      sectionTypeId: "tableForm",
      fieldName: "items",
      expandable: true,
      isExpand: true,
      hasNoAction: false,
      hasProgress: false,
      hasAutoFillButton: false,
      tableColumns: [
        {
          Header: () => {
            return (
              <FormTableHeaderCell
                text={"Product Code/Name"}
                required={true}
              />
            )
          },
          accessor: "item",
          width: 200,
          Cell: ({ row }) => {
            const endpointUrl = "/items";
            const validationProps = {
              required: "Product Code/Name is required"
            }

            const isMobileView = useMediaQuery({ maxWidth: 991 });

            return (<FormTableSelect
              fieldName={"items"}
              fieldItemName={"Item"}
              rowNumber={row.id}
              endpointUrl={endpointUrl}
              validationProps={validationProps}
              disabled={row.isDisable}
              headerName={"Product Code/Name"}
              isMobileView={isMobileView}
              shouldTriggerSelectedField={true}
            />)
          }
        },
        {
          Header: () => {
            return (
              <FormTableHeaderCell
                text={"Initial Quantity On Hand"}
                required={false}
              />
            )
          },
          accessor: "quantityOnHand",
          width: 110,
          Cell: ({ row }) => {
            const isMobileView = useMediaQuery({ maxWidth: 991 });

            return (<FormTableTextField
              fieldName={"items"}
              fieldItemName={"qtyOnHand"}
              rowNumber={row.id}
              valueType={"number"}
              validationProps={false}
              align="right"
              disabled={true}
              headerName={"Initial Quantity On Hand"}
              isMobileView={isMobileView}
            />)
          }
        },
        {
          Header: () => {
            return (
              <FormTableHeaderCell
                text={"Quantity Change"}
                required={false}
              />
            )
          },
          accessor: "qtyChange",
          width: 110,
          Cell: ({ row }) => {
            const isMobileView = useMediaQuery({ maxWidth: 991 });

            return (<FormTableTextField
              fieldName={"items"}
              fieldItemName={"qtyChange"}
              rowNumber={row.id}
              valueType={"number"}
              placeHolder={"Eg. 10, -10"}
              validationProps={false}
              align="right"
              disabled={row.isDisable}
              headerName={"Quantity Change"}
              isMobileView={isMobileView}
            />)
          }
        },
        {
          Header: () => {
            return (
              <FormTableHeaderCell
                text={"New Quantity On Hand"}
                required={false}
              />
            )
          },
          accessor: "newQuantityOnHand",
          width: 110,
          Cell: ({ row }) => {
            const isMobileView = useMediaQuery({ maxWidth: 991 });

            return (
              <FormTableCalculateDisplay
                fieldName={"items"}
                rowNumber={row.id}
                calculateType="add-subtract"
                valuesToCalculate={["qtyOnHand", "qtyChange"]}
                headerName={"New Quantity On Hand"}
                isMobileView={isMobileView}
              />
            )
          }
        },
        {
          id: "action",
          width: 70,
          Cell: ({ row, toggleRowSelected }) => {
            const uuid = row.original.uuid;
            return (<FormTableInlineDelete
              rowNumber={row.id}
              toggleRowSelected={toggleRowSelected}
            />)
          }
        }
      ]
    },
    auditHistoryDetails: {
      sectionType: "Audit History details",
      sectionTypeId: "auditHistoryDetails",
      multiForm: false,
      expandable: true,
      isExpand: false,
      showEditOnly: true,
      addButton: true,
      position: "LAST",
      breakPoint: 1,
      fieldName: "auditHistory",
      fieldItems: [
        {
          label: "Created By",
          type: "text",
          name: "createdBy",
          initialFocus: false,
          disabled: true
        },
        {
          label: "Updated By",
          type: "text",
          name: "updatedBy",
          initialFocus: false,
          disabled: true
        },
      ]
    },
  },
  watchFields: [
    "stockAdjustment.0.Location",
  ]
}
