import { useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import ListTableBody from '../../../components/list-table-body/list-table-body.componenet';
import ListHeader from '../../../components/list-header/list-header.component';

import { DEPARTMENTS_COLUMNS } from './departments-columns.data';
import { LOCAL_FILTER_FIELDS } from './departments-local-filter-filters.data';
import { setListType, setLoadedData, setPagePath } from '../../../redux/list/list.actions';
import { setDetailPagePath } from '../../../redux/detail/detail.actions';
import { setFilterEmpty } from '../../../redux/filter/filter.actions';
import { selectListType } from '../../../redux/list/list.reselect';
import { withRouter } from 'react-router-dom';
import { setIsEditByFullForm } from '../../../redux/form/form.actions';
import { useState } from 'react';
import { setTabTitle } from '../../../utils/tab-title.utils';

const LIST_TYPE = "LIST_ITEMS";

const TYPE = {
    DEPARTMENT: "DEPARTMENT",
}

const DepartmentList = ({
    setShowAddPurchaseOrderModal,
    setListType,
    setLoadedData,
    setModalType,
    setFilterEmpty,
    listType,
    history,
    setIsEditByFullForm,
    setPagePath
}) => {
    const [confrimLoadedData, setConfirmLoadedData] = useState(false);

    const endpoint = "/departments";
    const pagePath = "/departments"

    const handleAddItem = () => {
        history.push(`${pagePath}/new`);
    }

    useEffect(() => {
        if (listType !== TYPE.DEPARTMENT) {
            setLoadedData({ data: null, skip: null, totalNumItems: 0 });
            setFilterEmpty();
        }

        setListType(TYPE.DEPARTMENT);
        setPagePath(pagePath);
        setIsEditByFullForm(true);
        setConfirmLoadedData(true);
    }, [])

    useEffect(() => {
        return () => {
            setIsEditByFullForm(false);
            setPagePath(null);
        }
    }, [])

    useEffect(() => {
        setTabTitle(`Department`)
    }, [])

    return (
        confrimLoadedData && 
        <>
            <ListHeader
                buttonAction={handleAddItem}
                buttonActionLabel={"Add Department"}
                endpoint={endpoint}
                title={"Departments"}
            />
            <ListTableBody
                tableColumns={DEPARTMENTS_COLUMNS}
                localFilterFields={LOCAL_FILTER_FIELDS}
                endpoint={endpoint}
            />
        </>
    )
};

const mapStateToProps = createStructuredSelector({
    listType: selectListType
})

const mapDispatchToProps = (dispatch) => ({
    setListType: (listType) =>
        dispatch(setListType(listType)),
    setLoadedData: (data) =>
        dispatch(setLoadedData(data)),
    setFilterEmpty: () =>
        dispatch(setFilterEmpty()),
    setIsEditByFullForm: (data) =>
        dispatch(setIsEditByFullForm(data)),
    setPagePath: (data) =>
        dispatch(setPagePath(data))
})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DepartmentList))