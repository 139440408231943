import { format } from 'date-fns';
import FormTableCalculateDisplay from '../../../components/form-table-calculate/form-table-calculate.component';
import FormTableDatePicker from '../../../components/form-table-date-picker/form-table-date-picker.component';
import FormTableHeaderCell from '../../../components/form-table-header-cell/form-table-header-cell.component';
import FormTableInlineDelete from '../../../components/form-table-inline-delete/form-table-inline-delete.component';
import FormTableSelect from '../../../components/form-table-select/form-table-select.component';
import FormTableTextField from '../../../components/form-table-text-field/form-table-text-field.component';
import { displayCellValue } from '../../../utils/column-values.util';
import { useMediaQuery } from 'react-responsive';

export const SHIP_INFO = {
  formTitle: "Sales Order",
  path: "/salesorders",
  subPath: "/shippings",
  listPage: "/sales-orders",
}

export const SHIP_FORM_TEMPLATE = {
  bodySections: {
    basic: {
      sectionType: "basic",
      sectionTypeId: "BASIC",
      expandable: false,
      breakPoint: 1,
      fieldName: "salesOrder",
      fieldItems: [
        {
          label: "SO Number",
          type: "text",
          name: "salesOrderNumber",
          initialFocus: true,
          disabled: true,
          defaultValue: `SO-.YYYY.-`
        },
        {
          label: "SO Date",
          type: "date",
          name: "salesOrderDate",
          initialFocus: false,
          disabled: true,
          validationProps: {
            required: "SO date is required"
          }
        },
        // {
        //   label: "Assign To",
        //   type: "select",
        //   name: "User",
        //   required: false,
        //   initialFocus: false,
        //   disabled: true,
        //   muliselect: false,
        //   url: "/users"
        // }
      ]
    },
    tableForm: {
      sectionType: "Items",
      sectionTypeId: "tableForm",
      fieldName: "items",
      expandable: true,
      isExpand: true,
      hasNoAction: true,
      hasProgress: false,
      tableColumns: [
        {
          Header: () => {
            return (
              <FormTableHeaderCell
                text={"Ship Date"}
                required={true}
              />
            )
          },
          accessor: "shipDate",
          width: 60,
          Cell: ({ row }) => {
            const endpointUrl = "/locations";
            const validationProps = {
              required: "Location is required"
            }

            const isMobileView = useMediaQuery({ maxWidth: 991 });

            return (<FormTableDatePicker
              fieldName={"items"}
              fieldItemName={"shippingDate"}
              rowNumber={row.id}
              value={row.value}
              validationProps={validationProps}
              disabled={row.isDisable}
              headerName={"Ship Date"}
              isMobileView={isMobileView}
            />)
          }
        },
        {
          Header: () => {
            return (
              <FormTableHeaderCell
                text={"Box Number"}
                required={true}
              />
            )
          },
          accessor: "boxNumber",
          width: 100,
          Cell: ({ row }) => {
            const isMobileView = useMediaQuery({ maxWidth: 991 });

            return (<FormTableTextField
              fieldName={"items"}
              fieldItemName={"boxNumber"}
              rowNumber={row.id}
              valueType={"number"}
              align="left"
              disabled={row.isDisable}
              headerName={"Box Number"}
              isMobileView={isMobileView}
            />)
          }
        },
        {
          Header: () => {
            return (
              <FormTableHeaderCell
                text={"Carrier"}
                required={false}
              />
            )
          },
          accessor: "carrier",
          width: 80,
          Cell: ({ row }) => {
            const isMobileView = useMediaQuery({ maxWidth: 991 });

            return (<FormTableTextField
              fieldName={"items"}
              fieldItemName={"shippingCarrier"}
              rowNumber={row.id}
              valueType={"text"}
              align="left"
              disabled={row.isDisable}
              headerName={"Carrier"}
              isMobileView={isMobileView}
            />)
          }
        },
        {
          Header: () => {
            return (
              <FormTableHeaderCell
                text={"Tracking Number"}
                required={false}
              />
            )
          },
          accessor: "trackingNumber",
          width: 120,
          Cell: ({ row }) => {
            const isMobileView = useMediaQuery({ maxWidth: 991 });

            if (isMobileView) {
              return null;
            }

            return (<FormTableTextField
              fieldName={"items"}
              fieldItemName={"trackingNumber"}
              rowNumber={row.id}
              valueType={"text"}
              align="left"
              disabled={row.isDisable}
            />)
          }
        },
        {
          Header: () => {
            return (
              <FormTableHeaderCell
                text={"Shipped By"}
                required={false}
              />
            )
          },
          accessor: "shippedBy",
          width: 90,
          Cell: ({ row }) => {
            const isMobileView = useMediaQuery({ maxWidth: 991 });

            if (isMobileView) {
              return null;
            }

            return (<FormTableTextField
              fieldName={"items"}
              fieldItemName={"shippedBy"}
              rowNumber={row.id}
              validationProps={false}
              disabled={true}
              align="left"
            />)
          }
        },
        {
          Header: () => {
            return (
              <FormTableHeaderCell
                text={"Ship Status"}
                required={true}
              />
            )
          },
          accessor: "shippingStatus",
          width: 100,
          Cell: ({ row }) => {
            const endpointUrl = "/shippingstatuses";
            const validationProps = {
              required: "Ship Status is required"
            }

            const isMobileView = useMediaQuery({ maxWidth: 991 });

            return (<FormTableSelect
              fieldName={"items"}
              fieldItemName={"shippingStatus"}
              rowNumber={row.id}
              value={row.value}
              defaultValue={{ value: 1, label: "Not Shipped" }}
              endpointUrl={endpointUrl}
              validationProps={validationProps}
              disabled={row.isDisable}
              headerName={"Ship Status"}
              isMobileView={isMobileView}
            />)
          }
        },
        // {
        //   accessor: "empty",
        //   width: 100,
        // }
      ]
    },
  },
  watchFields: []
}
